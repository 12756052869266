"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SensorTabIndex = exports.SensorClassName = exports.SizeSensorId = void 0;

/**
 * Created by hustcc on 18/6/9.
 * Contract: i@hust.cc
 */
var SizeSensorId = 'size-sensor-id';
exports.SizeSensorId = SizeSensorId;
var SensorClassName = 'size-sensor-object';
exports.SensorClassName = SensorClassName;
var SensorTabIndex = '-1';
exports.SensorTabIndex = SensorTabIndex;